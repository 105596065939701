import { useCallback, useRef, Fragment } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import PriceFunction from '#components/PriceFunction.jsx'
import { loaderProp } from '#root/utils/image-utils'
import basepath from '#components/basepath'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { servicePackageUrlStructure } from '#root/utils/url-utils'
import ListProducts from '#components/ListProducts.jsx'

export default function ProductSlider({ is_service, title, description, tag, items, translatedCategories, pharmacy, locale, location, collection, addToCart }) {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, dragFree: true, align: 'start' })
  const emblaContainerRef = useRef(null)
  const { t } = useTranslation(['common','pharmacyProduct'])

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  return (
    <>
      <div className="slider-container pos-rel">
        <div className="pl-1 pr-1 pb-1 wrap-1">
          <ul className="controls flex-box fullwidth ai-b" aria-label="Carousel Navigation" tabIndex={1}>
            {!addToCart&&
              <>
                  <li onClick={scrollPrev} className="prev" aria-controls="s2" tabIndex={-1} data-controls="prev"> <i><img src={basepath("/images/prev.svg")} alt="prev" /></i> </li>
                  <li onClick={scrollNext} className="next" aria-controls="s2" tabIndex={-1} data-controls="next"> <i><img src={basepath("/images/next.svg")} alt="next" /></i> </li>
              </>
            }
            <li className="headline">
              <h2>
                {title ? title : t('relatedItems')}
                <span>
                  {tag}
                </span>
              </h2>
              <span className='inlineCarouselDescription' dangerouslySetInnerHTML={{__html: description ? description : t('relatedItemsDesc')}}></span>
            </li>
            { !addToCart&&
              <li className="more"><a className="button-more" href={collection ? `${locale === 'en' ? '' : `/${locale}`}/featured-items/${collection.url_slug}` : '#'}>
                {
                  collection ?
                    <div>
                      <span>{t('viewMore')}</span>
                    </div>
                    :
                    <div className="dots">
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                    </div>
                }
              </a></li>
            }
          </ul>
        </div>
        {
          location == 'homeIndex' || items.length > 7 ?
            <div className="embla" ref={emblaRef}>
              {console.log('cdscdscs', items.length)}
              <div className="embla__container" ref={emblaContainerRef}>
                {items?.map((x, i) => {
                  let href = null
                  if (!is_service && translatedCategories ) {
                    href = basepath(`${locale === 'en' ? '' : `/${locale}`}/${pharmacy}/${translatedCategories[x?.product_category?.name]?.replace(/[^\w\s']/gi, ' ')?.replace("'", "")?.toLowerCase().trim().split(' ').join('-').replace(/-+/g, '-').replace(`%7D`, '')}/${x?.variants[0]?.uuid}`)
                    console.log('hrefhrefhrefhref', href)}
                  else if (is_service) {
                    href = basepath(servicePackageUrlStructure({ package: x, store: x?.store, locale, mode: 'service' }))
                  }
                  return href && (
                    <Fragment key={i}>
                      <div className="slider-item tns-item embla__slide">
                        <a
                          className="card"
                          href={href}
                        >
                          <div className="img pos-rel">

                            {x?.discount && (
                              <div className="overlay-discount pos-abs">
                                {x.discount}% OFF
                              </div>
                            )}

                            <div className="category pos-abs">
                              <span>{x?.auto_generated_name}</span>
                            </div>

                            <Image
                              placeholder='blur'
                              blurDataURL={basepath('/images/c01.jpg')}
                              src={x?.data?.images?.length > 0 ? `${process.env.NEXT_PUBLIC_CDNPATH + x.data.images[0]}` : '/images/c01.jpg'}
                              alt={x?.data?.images?.length > 0 ? x?.name : "doctoroncall benefits"}
                              width={200}
                              height={200}
                              loader={loaderProp}
                            />
                            {is_service &&
                              <div className="category pos-abs"><span>{x.categories[0]?.name}</span></div>
                            }
                          </div>

                          <div className="desc">
                            <div className="afd details flex-box fullwidth fd-c gap-05">
                              <div>
                                {is_service &&
                                  <div className="location-1">{x?.store_name}</div>
                                }
                                <h2>{x?.data?.name || x?.name}</h2>
                              </div>
                              {
                                is_service && x.store?.address?.city &&
                                <div className="location-2">{x.store?.address?.city}</div>
                              }
                              <div className="price">
                                <p className="mb-05 fs-06">Starts from</p>
                                <div className="new">
                                  <PriceFunction
                                    currency={locale}
                                    value={x?.final_price || x?.price}
                                    discount={x.discount}
                                    beforeDiscount={x?.price}
                                  />
                                </div>
                              </div>

                            </div>
                          </div>
                        {
                          addToCart &&
                          <>
                            <div>
                              <button
                                className="add-cart-btn f-g1-s1 no-stock-remove button-red fullwidth"
                                onClick={async(e) => {
                                  e.stopPropagation();
                                  e.preventDefault(); 
                                  await addToCart({
                                        inventory_id: x?.inventories?.[0]?.id,
                                        quantity: 1,
                                        isProduct: true,
                                        newItem:true
                                    })
                                  }
                                } 
                              >
                                {t('addToCartButton',{ns:'pharmacyProduct'})}
                              </button>
                            </div>
                          </>
                        }
                        </a>
                        
                      </div>
                    </Fragment>
                  )
                })}
              </div>
            </div>
            :
            <div className='wrap-1'>
              <ListProducts
                isToggled={false}
                items={items}
                locale={locale}
                pharmacy={pharmacy}
              />
            </div>
        }
      </div>

      {/* Add CSS Styles */}
      <style jsx>{`
        .pos-rel {
          position: relative;
        }
        .pos-abs {
          position: absolute;
        }        
      `}</style>
    </>
  )
}