import PriceFunction from '#components/PriceFunction.jsx'
import { loaderProp } from '#root/utils/image-utils'
import basepath from '#components/basepath'
import Image from 'next/image'
import {servicePackageUrlStructure} from '#root/utils/url-utils'

export default function ListProducts({isToggled, items, pharmacy, locale,category}){
    return(
        <>
          <div className={`${isToggled ? 'col1' : 'col4'} filter-results flex-box fw gap-1`}>
            {items?.map((item, i) => (
              <div className="slider-item" key={i}>
                <a
                  className="card"
                  href={basepath(
                    item?.store
                      ? servicePackageUrlStructure({package:item, store:item?.store,locale,mode:'service'})
                      : `${
                          locale === 'en' ? '' : `/${locale}`
                        }/${pharmacy}/${category?category: item.product_category?.name.replace(/[^\w\s']/gi, ' ')
                          .replace("'", '')
                          .toLowerCase()
                          .trim()
                          .split(' ')
                          .join('-')
                          .replace(/-+/g, '-')
                          .replace(`%7D`, '')
                        }/${item.variants[0]?.uuid}
                          
                          `
                  )}
                >
                  <div className="img pos-rel">
                    <div className="category pos-abs">
                      <span>{item?.product_category?.name || item?.categories[0]?.name}</span>
                    </div>
                    <Image
                      placeholder='blur'
                      blurDataURL={basepath('/images/c01.jpg')}
                      src={                        
                        item?.data?.images?.length>0 ? 
                        process.env.NEXT_PUBLIC_CDNPATH + item?.data?.images[0]
                        : 
                        '/images/c01.jpg'
                      }
                      alt={item?.data?.images?.length>0 ? item?.data?.name : "doctoroncall benefits"}
                      width={0}
                      height={0}
                      sizes="100vw"
                      loader={loaderProp}
                      // className='mediaImg'
                    />  
                  </div>
                  <div className="desc">
                    <div className="af details flex-box fullwidth fd-c gap-05" >
                      <div >
                        {item && item.store && item.store.store_name && (
                          <div className="location-1">{item.store.store_name}</div>
                        )}
                        <h2 className="ellipsis"style={{height:'min-content'}}>{item?.data?.name || item.name}</h2>
                      </div>
                      {item.store && item.store.address && (
                        <div className="location-2">{item.store.address.city}</div>
                      )}
                      <div className="price">
                        {item?.no_stock == true ? (
                          <span className="oos">Out of Stock</span>
                        ) : (
                          <>
                            <p className="mb-05 fs-06">Starts from</p>
                            <div className="new">
                              <PriceFunction
                                currency={locale}
                                value={item?.final_price || item?.price} 
                                discount={item.discount}
                                beforeDiscount={item?.price}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </>
    )
}